// 
// timeline.scss
//

/************** Horizontal timeline **************/ 

.left-timeline{
    .events{
        border-left: 3px solid $gray-300;
        padding-left: 35px;
        .event-list{
            // position: relative;
            &::before{
                content: "";
                position: absolute;
                left: -45px;
                top: 6px;
                height: 18px;
                width: 18px;
                background-color: $primary;
                border-radius: 50%;
                border: 5px solid $card-bg;
                z-index: 9;
            }
            &::after{
                content: "";
                position: absolute;
                left: -38px;
                top: 14px;
                height: 3px;
                width: 30px;
                background-color: $gray-300;
            }
        }
    }

    .album {
        img {
            height: 36px;
            width: auto;
            border-radius: 4px;
        }
    }
}


// timeline center


.timeline {
    margin-bottom: 50px;
    position: relative;
    &:before {
        background-color: $gray-300;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 30px;
        width: 2px;
        z-index: 0;
    }
    .time-show {
        margin-bottom: 30px;
        margin-top: 30px;
        position: relative;
        text-align: center;
    }
    .timeline-box {
        display: block;
        position: relative;
        padding: 20px;
    }
    .timeline-album {
        margin-top: 12px;
        a {
            display: inline-block;
            margin-right: 5px;
        }
        img {
            height: 36px;
            width: auto;
            border-radius: 3px;
        }
    }
}

@include media-breakpoint-up(md) {
    .timeline {
        .time-show {
            right: 69px;
        }
        .timeline-box {
            margin-left: 15px;
        }
        .timeline-icon {
            background: $primary;
            border-radius: 50%;
            display: block;
            height: 20px;
            left: -24px;
            margin-top: -10px;
            position: absolute;
            text-align: center;
            top: 15%;
            width: 20px;
            border: 5px solid $card-bg;
            z-index: 9;
            &:before {
                content: "";
                position: absolute;
                height: 3px;
                width: 19px;
                background-color: $gray-300;
                left: 15px;
                top: 3px;
            }
        }
        .timeline-desk {
            display: table-cell;
            vertical-align: top;
            width: 50%;
        }
    }
    .timeline-item {
        display: flex;
        &:before {
            content: "";
            display: block;
            width: 50%;
        }

        &.timeline-item-left {
            &:after {
                content: "";
                display: block;
                width: 50%;
            }
            .timeline-desk {
                .album {
                    float: right;
                    margin-top: 20px;
                    a {
                        float: right;
                        margin-left: 5px;
                    }
                }
            }
            .timeline-icon {
                left: auto;
                right: -26px;
                &:before{
                    left: auto;
                    right: 15px;
                }
            }
            &:before {
                display: none;
            }
            .timeline-box {
                margin-right: 15px;
                margin-left: 0;
                text-align: left;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .timeline {
        .time-show {
            position: relative;
        }
        .event-date{
            float: left !important;
            margin-right: 1.5rem;
            margin-left: 0 !important;
        }
        .timeline-icon {
            display: none;
        }
        
    }
}